import { Module } from 'vuex'
import ShippingModuleState from '../types/shipping-module-state'
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import RootState from '@vue-storefront/core/types/RootState'

const state: ShippingModuleState = {
  current: {
    city: null,
    method: null,
    shop: null,
    type: null,
    group: [],
    address: null,
    npType: null,
    npCity: null,
    npShop: null
  },
  draft: {
    city: null,
    method: 'DELIVERY',
    shop: null,
    type: null,
    group: [],
    address: null,
    npType: 'department',
    npCity: null,
    npShop: null
  },
  loading: {
    shipping: true,
    shopList: false,
    geocode: false
  },
  loaded: {
    shipping: false,
    shopList: false,
    geocode: false
  },
  shopDeliveryPolygon: false,
  deliveryCache: {
    coordinates: {
      lat: 0,
      lng: 0
    },
    ttl: 0,
    shopDeliveryPolygon: false
  },
  defaultCity: {},
  defaultShop: {},
  currentRegion: {},
  shopList: [],
  cityList: [],
  regionList: [],
  npCenter: [],
  npCityList: [],
  npShopList: [],
  cityListForGeocode: [],
  deliveryPolygon: {
    tms_id: null,
    list: []
  }
}

export const shippingModule: Module<ShippingModuleState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { PromoOnlyStore } from './store'

export const PromoOnlyModule: StorefrontModule = function ({ store }, fromCache) {
  if (fromCache?.['promo-only']) {
    PromoOnlyStore.state = fromCache['promo-only']
  }

  store.registerModule('promo-only', PromoOnlyStore)
}

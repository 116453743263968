import { mapActions, mapState } from 'vuex';

export default {
  name: 'ShippingLocationMixin',
  methods: {
    ...mapActions({
      loadShipping: 'shipping-module/loadShipping',
      setDeliveryByLocation: 'shipping-module/setDeliveryByLocation'
    }),
    async currentLocationCallback (currentLocation) {
      if (!currentLocation?.coords) return

      const coordinates = {
        lat: currentLocation?.coords?.latitude,
        lng: currentLocation?.coords?.longitude
      }

      await this.setDeliveryByLocation(coordinates)
    },
    requestCurrentLocation () {
      try {
        navigator.geolocation.getCurrentPosition(
          this.currentLocationCallback.bind(this)
        );
      } catch (e) {}
    }
  },
  mounted () {
    this.requestCurrentLocation()

    if (this.loadedShipping) return

    this.loadShipping({
      onlyLocalStorage: true
    })
  },
  computed: {
    ...mapState({
      loadedShipping: state => state['shipping-module'].loaded.shipping
    })
  }
}

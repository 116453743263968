import { StorefrontModule } from '@vue-storefront/core/lib/modules'

import { shippingModule } from './store'

export const KEY = 'shipping-module'

export const ShippingModule: StorefrontModule = function ({ store }, fromCache: any) {
  if (fromCache?.[KEY]) {
    shippingModule.state = fromCache[KEY]
  }

  store.registerModule(KEY, shippingModule)
}

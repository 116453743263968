// TODO: Remove after time

export const shippingNewPostRemap = (i) => {
  if (!i.city_description) return i

  const r: any = {
    alcohol: i.alcohol_restriction, // i.alcohol ?
    number: i.number,
    ref: i.ref,
    c_ref: i.city_ref, // i.city ?
    postbox: i.is_post_machine, // i.post ?
    lat: +i.latitude, // i.lat ?
    long: +i.longitude, // i.lon ?
    wgh: i.max_weight, // i.wgh ?
    address: i.short_address // i.adr / i.address ?
  }

  if (i.label) r.label = i.label

  return r
}

import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { VarusCafeStore } from './store'

export const VarusCafeModule: StorefrontModule = function ({ store }, fromCache) {
  if (fromCache?.['varus-cafe']) {
    VarusCafeStore.state = fromCache['varus-cafe']
  }

  store.registerModule('varus-cafe', VarusCafeStore)
}

import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { SpecialOffersStore } from './store'

export const SpecialOffersModule: StorefrontModule = function ({ store }, fromCache) {
  if (fromCache?.['special-offers']) {
    SpecialOffersStore.state = fromCache['special-offers']
  }

  store.registerModule('special-offers', SpecialOffersStore)
}

import { baseFilterProductsQuery } from 'theme/helpers/overrideQueries';
import config from 'config';
import { SearchQuery } from 'storefront-query-builder';
import { applyFilterFromToByDatetime } from '$modules/banner/helpers';
import { promotionFilterQuery } from 'theme/store/category-extension/helpers/promotionFilter';

export function buildFilterProductsQuery ({
  currentCategory,
  chosenFilters = {},
  defaultFilters = null,
  queryText = '',
  toSkipCat = [],
  shopId = null,
  route = null
}) {
  const filters = currentCategory?.filterattributes ? currentCategory.filterattributes?.split(',') : config.products.defaultFilters

  if (currentCategory.id !== toSkipCat[0] && !filters.includes('pim_brand_id')) {
    filters.unshift('pim_brand_id')
  }
  if (currentCategory.id === toSkipCat[0] && filters.includes('pim_brand_id')) {
    filters.splice(filters.indexOf('pim_brand_id'), 1)
  }

  let filterQr = baseFilterProductsQuery(currentCategory, defaultFilters == null ? filters : defaultFilters, toSkipCat)

  if (queryText.length) {
    filterQr.setSearchText(queryText)
  }

  // add chosen filters
  for (const code of Object.keys(chosenFilters)) {
    let filter = chosenFilters[code]
    if (Array.isArray(filter) && filter[0].type === config.attributes.filterIsPromo.type) {
      filter = config.attributes.filterIsPromo.type
    }

    const attributeCode = Array.isArray(filter) ? filter[0].attribute_code : filter.attribute_code
    if (Array.isArray(filter) && attributeCode !== 'price') {
      const values = filter.map(filter => filter.id)
      filterQr = filterQr.applyFilter({ key: attributeCode, value: { 'in': values }, scope: 'catalog' })
    } else if (filter === config.attributes.filterIsPromo.type) {
      filterQr = filterQr.applyFilter({ key: filter, value: { 'in': shopId ? [shopId] : null }, scope: 'default' })
    } else if (attributeCode !== 'price') {
      filterQr = filterQr.applyFilter({ key: attributeCode, value: { 'eq': filter.id }, scope: 'catalog' })
    } else if (attributeCode === 'price') {
      filterQr.applyFilter({ key: `sqpp_data_${shopId}.sort_price`,
        value: {
          'gte': route.query.price.split('-')[0] || filter[0].price_min.value,
          'lte': route.query.price.split('-')[1] || filter[0].price_max.value
        },
        scope: 'default' })
    } else { // multi should be possible filter here?
      const rangeqr = {}
      const filterValues = Array.isArray(filter) ? filter : [filter]
      filterValues.forEach(singleFilter => {
        if (singleFilter.from) rangeqr['gte'] = singleFilter.from
        if (singleFilter.to) rangeqr['lte'] = singleFilter.to
      })
      filterQr = filterQr.applyFilter({ key: attributeCode, value: rangeqr, scope: 'catalog' })
    }
  }

  filterQr.addAvailableFilter({ field: 'has_promotion_in_stores', scope: 'catalog' })
  filterQr.addAvailableFilter({ field: 'category_ids', scope: 'catalog' })
  filterQr.addAvailableFilter({ field: 'price',
    scope: 'catalog',
    options: {
      'shop_id': shopId,
      'version': '2'
    } })

  filterQr.applyFilter(promotionFilterQuery(shopId))

  return filterQr
}

export const calcQueryStart = ({ areFiltersEmpty, routerQuery, pageSize }) =>
  areFiltersEmpty ? 0 : ((Number(routerQuery.page) || 1) - 1) * pageSize;
export const applyAdditionalFilters = ({ additionalFilters, query }) => {
  for (const filter of additionalFilters) {
    Object.entries(filter).forEach(([key, value]) => {
      query.applyFilter({ key: key, value: value });
    });
  }
  return query;
}

export function buildBannerQuery (categoryId) {
  const query = new SearchQuery();
  applyFilterFromToByDatetime(query)
  query.applyFilter({ key: 'category_ids', value: { 'in': categoryId } })
  query.applyFilter({ key: 'status', value: { 'eq': '1' } })
  return query;
}

export const SN_PROMO_ONLY = 'promo-only'

export const PROMO_ONLY_SET_CATEGORIES = `${SN_PROMO_ONLY}/PROMO_ONLY_SET_CATEGORIES`
export const PROMO_ONLY_SET_PROMO_ONLY_CATEGORY = `${SN_PROMO_ONLY}/PROMO_ONLY_SET_PROMO_ONLY_CATEGORY`
export const PROMO_ONLY_ADD_PRODUCTS = `${SN_PROMO_ONLY}/PROMO_ONLY_ADD_PRODUCTS`
export const PROMO_ONLY_UPDATE_PRODUCTS = `${SN_PROMO_ONLY}/PROMO_ONLY_UPDATE_PRODUCTS`
export const PROMO_ONLY_SET_CATEGORY_FILTERS = `${SN_PROMO_ONLY}/PROMO_ONLY_SET_CATEGORY_FILTERS`
export const PROMO_ONLY_SET_CURRENT_CATEGORY = `${SN_PROMO_ONLY}/PROMO_ONLY_SET_CURRENT_CATEGORY`
export const PROMO_ONLY_SET_SEARCH_PRODUCTS_STATS = `${SN_PROMO_ONLY}/PROMO_ONLY_SET_SEARCH_PRODUCTS_STATS`
export const PROMO_ONLY_SET_CATEGORIES_IS_LOADING = `${SN_PROMO_ONLY}/PROMO_ONLY_SET_CATEGORIES_IS_LOADING`
export const PROMO_ONLY_SET_PRODUCTS_IS_LOADING = `${SN_PROMO_ONLY}/PROMO_ONLY_SET_PRODUCTS_IS_LOADING`
export const SET_IS_ADDITIONAL_LOADING = `${SN_PROMO_ONLY}/SET_IS_ADDITIONAL_LOADING`;
export const SET_BANNERS = `${SN_PROMO_ONLY}/SET_BANNERS`;

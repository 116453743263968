import { ActionTree } from 'vuex';
import PromotionState from '../types/PromotionState';
import RootState from '@vue-storefront/core/types/RootState'
import * as types from './mutation-types';
import * as specialOffersTypes from '$modules/special-offers/store/mutation-types';
import { SearchQuery } from 'storefront-query-builder';
import { getCurrentRegion, getCurrentShipping, getCurrentShopId, isCurrentNewPost } from 'theme/store/checkout/helpers';
import { buildFilterAvailableQuery, buildFilterProductsQuery } from '../helpers';
import { SET_PRODUCT_AGGREGATION } from 'theme/store/category-extension/store/mutation-types';
import modulesConfig from '$modules/config'
import { quickSearchByQuery } from 'src/search/adapter/api-search-query-varus/search'
import { router } from '@vue-storefront/core/app'
import FilterVariant from '@vue-storefront/core/modules/catalog-next/types/FilterVariant';
import { changeFilterQuery } from '@vue-storefront/core/modules/catalog-next/helpers/filterHelpers';
import { isServer } from '@vue-storefront/core/helpers'
import { PAGE_SIZE, PRODUCTS_PAGE_SIZE } from '../config';
import { applyAdditionalFilters, calcQueryStart } from '$modules/special-offers/helpers';
import { aggregationRemap } from 'theme/helpers/product';
import { HumanFilter } from 'theme/helpers/humanFilter';

const actions: ActionTree<PromotionState, RootState> = {

  async fetchPromotionCollection ({ commit, dispatch, state }, { route, start = 0 } = {}) {
    if (route.query && route.query.page) {
      start = (+route.query.page - 1) * PAGE_SIZE;
    }

    await dispatch('fetchPromotionCategory')

    const currentQuery = route.query;
    const queryPage = new SearchQuery()

    if (currentQuery.hasOwnProperty('cat')) {
      commit(types.SET_PROMOTION_CURRENT_CATEGORY_COLLECTION, { cat: currentQuery.cat })
      const currentCategory = state.promotionCurrentCategory

      if (Number(currentCategory.cat) !== 0) {
        queryPage.applyFilter({
          key: 'category_ids',
          value: { 'in': currentCategory.cat },
          scope: 'default'
        })
      }
    }

    if (currentQuery.hasOwnProperty('type')) {
      commit(types.SET_PROMOTION_CURRENT_TYPE_COLLECTION, { type: currentQuery.type })
      const currentType = state.promotionCurrentType
      if (Number(currentType.type) !== 0) {
        queryPage.applyFilter({
          key: 'action_type',
          value: { 'in': currentType.type },
          scope: 'default'
        })
      }
    }

    queryPage.applyFilter({ key: 'date_from', value: { 'lte': 'now' } })
    const { items, perPage, total } = await quickSearchByQuery({
      query: queryPage,
      start,
      size: PAGE_SIZE,
      entityType: 'promotion_page',
      sort: 'date_to:asc'
    });

    const isAdditionalLoading = state.isAdditionalPromotionsLoading

    commit(isAdditionalLoading ? types.UPDATE_PROMOTIONS : types.SET_PROMOTIONS, items);
    dispatch('ui/setPagingLoading', false, { root: true })
    commit(types.SET_SEARCH_PRODUCTS_STATS, { perPage, start, total })

    await dispatch('homepage/loadSampleProductsCategory', 2, { root: true })
  },
  async fetchPromotionCategory ({ commit }) {
    const queryCategory = new SearchQuery()
    const promotionCategory = await quickSearchByQuery({
      query: queryCategory,
      entityType: 'promotion_category'
    });
    commit(types.SET_PROMOTION_CATEGORY_COLLECTION, promotionCategory)
  },
  async fetchMainBrandsCollection ({ commit }) {
    const query = new SearchQuery()

    query.applyFilter({ key: 'display_on_main_brand', value: { 'eq': 1 }, scope: 'default' })
    const resp = await quickSearchByQuery({ entityType: 'category', query: query, size: 100 })

    commit(types.SET_BRANDS_MAIN_COLLECTION, resp.items)
  },
  async fetchPromotionMetaData ({ commit }) {
    const query = new SearchQuery()

    query.applyFilter({
      key: 'config_path',
      value: {
        'in': [
          'category_seo_h1',
          'category_seo_meta_title',
          'category_seo_meta_keywords',
          'category_seo_meta_description',
          'category_seo_block_text'
        ]
      },
      scope: 'default'
    })
    const resp = await quickSearchByQuery({ entityType: 'config', query })

    if (Array.isArray(resp.items)) {
      const metaData = {}
      resp.items.forEach(e => {
        if (e.config_path) metaData[e.config_path] = e.config_value
      })

      commit(types.SET_PROMOTION_METADATA, metaData)
    }
  },
  async switchFilters ({ dispatch, commit }, filterVariants: FilterVariant[] = []) {
    let currentQuery = router.currentRoute.query;
    filterVariants.forEach(filterVariant => {
      if (currentQuery.hasOwnProperty('cat') && filterVariant['type'] === 'cat') {
        delete currentQuery.cat
      }

      currentQuery = changeFilterQuery({ currentQuery, filterVariant });

      if (currentQuery.cat.toString() === [0].toString()) {
        currentQuery.cat = []
      }
    })

    commit(types.SET_PROMOTION_CURRENT_CATEGORY_COLLECTION, currentQuery)
    await dispatch('changeRouterFilterParameters', currentQuery);
  },
  async switchFiltersType ({ dispatch, commit }, filterVariants: FilterVariant[] = []) {
    let currentQuery = router.currentRoute.query;

    filterVariants.forEach(filterVariant => {
      if (currentQuery.hasOwnProperty('type') && filterVariant['type'] === 'type') {
        delete currentQuery.type
      }

      currentQuery = changeFilterQuery({ currentQuery, filterVariant });

      if (currentQuery.type.toString() === [0].toString()) {
        currentQuery.type = []
      }
    })
    commit(types.SET_PROMOTION_CURRENT_TYPE_COLLECTION, currentQuery)
    await dispatch('changeRouterFilterParameters', currentQuery);
  },
  async changeRouterFilterParameters (context, query) {
    if ('page' in query) {
      delete query['page'];
    }

    await router.push({ query: query });
  },
  changeRouterFilterParametersHF (ctx, url) {
    router.push(url)
  },
  async currentPage ({ commit, state }, { route } = {}) {
    const slug = new HumanFilter(route.params.slug).getSlug()

    if (slug === state.page?.slug) return

    let queryPage = new SearchQuery()

    queryPage = queryPage.applyFilter({ key: 'url_key', value: { 'in': slug }, scope: 'default' })
    const response = await quickSearchByQuery({
      query: queryPage,
      entityType: 'promotion_page'
    });

    const promotionPage = response.items.length ? response.items[0] : {}

    commit(types.SET_CURRENT_PAGE, promotionPage)
  },
  async loadPromotionProducts ({
    dispatch,
    commit,
    state,
    rootGetters,
    rootState
  }, {
    route,
    category,
    start = 0
  } = {}) {
    if (!category) return;

    const shopId = await getCurrentShopId()
    if (!shopId) return
    const shouldSkipSqpp = state.page['skip_sqpp_special_price']

    const additionalFilters = [
      {
        'promotion_page_id': {
          'eq': state.page.id
        }
      }
    ]
    if (!isServer) {
      await dispatch('loadCategoryFilters', { category, additionalFilters, route });
    }

    const { id: categoryId } = category;

    const { query: routerFiltersSource } = route;

    const filtersHuman = new HumanFilter(route.fullPath)
    const filtersHumanURI = filtersHuman.getUri()

    const mappedFilters = rootState['special-offers'].filtersMap[categoryId];

    let filtersHumanPrepared = {}

    if (!mappedFilters) {
      filtersHumanPrepared = await dispatch(
        'category-extension/prepareHumanFilters',
        { category: category, filtersHuman },
        { root: true }
      )
    }

    const routerQuery = { ...routerFiltersSource, ...filtersHumanPrepared }

    const searchQuery = rootGetters['special-offers/getCurrentFiltersFrom'](routerQuery, mappedFilters, filtersHumanURI);
    const { filters: chosenFilters, sort } = searchQuery;
    const filterQuery = buildFilterProductsQuery({ chosenFilters, currentCategory: category, shopId, route });
    const filterAvailableQuery = buildFilterAvailableQuery({ currentCategory: category, route });

    if (additionalFilters.length) {
      applyAdditionalFilters({ additionalFilters, query: filterQuery })
      applyAdditionalFilters({ additionalFilters, query: filterAvailableQuery })
    }

    const areFiltersEmpty = !Object.keys(routerQuery).length;
    start = calcQueryStart({ areFiltersEmpty, routerQuery, pageSize: PRODUCTS_PAGE_SIZE });
    const sortOrder = sort || routerQuery.sort || 'popularity:desc';

    if (routerQuery.cat) {
      const catIdFilter = { key: 'category_ids', value: { 'in': category.id } }

      filterQuery.applyFilter(catIdFilter)
      filterAvailableQuery.applyFilter(catIdFilter)
    }
    if (shouldSkipSqpp) {
      filterQuery.applyFilter({
        key: `sqpp_data_${shopId}.special_price`,
        value: { 'nin': null },
        scope: 'default'
      })
    }

    const availableToday = searchQuery.hasOwnProperty('available_today')

    const { items, aggregations, attributeMetadata, perPage, total } = await dispatch('product/findProducts', {
      query: filterQuery,
      availableToday,
      sort: sortOrder,
      start,
      size: PRODUCTS_PAGE_SIZE,
      includeFields: modulesConfig.smallProduct.includeFields,
      excludeFields: modulesConfig.smallProduct.excludeFields
    }, { root: true });

    const currentShipping = getCurrentShipping()
    const currentRegion = getCurrentRegion()
    const isNewPost = isCurrentNewPost()

    if ((!currentShipping?.method && currentRegion?.id) || isNewPost) {
      const productsInStock = await dispatch('product/categoryProductsAvailable', {
        query: filterAvailableQuery
      }, { root: true })

      commit(types.AVAILABLE_PRODUCTS, productsInStock)
    } else {
      commit(types.AVAILABLE_PRODUCTS, true)
    }

    const isAdditionalLoading = state.isAdditionalPromotionProductsLoading

    commit(isAdditionalLoading ? `special-offers/${specialOffersTypes.SPECIAL_OFFERS_UPDATE_PRODUCTS}` : `special-offers/${specialOffersTypes.SPECIAL_OFFERS_ADD_PRODUCTS}`, items, { root: true });
    dispatch('ui/setPagingLoading', false, { root: true })

    if (!isServer) {
      await dispatch('special-offers/loadAvailableFiltersFrom', {
        aggregations,
        attributeMetadata,
        category,
        filters: chosenFilters
      }, { root: true });
    }

    if (!isServer) {
      commit(`category-extension/${SET_PRODUCT_AGGREGATION}`, aggregations, { root: true });
      commit(`special-offers/${specialOffersTypes.SPECIAL_OFFERS_SET_PRODUCTS_IS_LOADING}`, false, { root: true });
    }
    commit(`special-offers/${specialOffersTypes.SPECIAL_OFFERS_SET_SEARCH_PRODUCTS_STATS}`, { perPage, start, total }, { root: true });

    return items;
  },
  async loadPromotionCategories ({ dispatch, state, rootGetters, commit }, { route }) {
    const additionalFilters = [
      {
        'promotion_page_id': {
          'eq': state.page.id
        }
      }
    ]

    const shopId = await getCurrentShopId()
    const category = rootGetters['special-offers/getSpecialOffersCategory'];
    const filterQr = buildFilterProductsQuery({ currentCategory: category, shopId, route })
    if (additionalFilters.length) applyAdditionalFilters({ additionalFilters, query: filterQr })

    const { aggregations } = await quickSearchByQuery({
      query: filterQr,
      excludeFields: ['*']
    })

    const categoryIds = aggregations.agg_terms_category_ids.buckets
    const categoryIdsArray = categoryIds.map(elem => elem.key)
    const categoryArray = await dispatch('special-offers/getCategoriesByChunk', { categoryIdsArray }, { root: true })

    const categories = categoryArray.filter(category => {
      return categoryIds.some(categoryId => {
        if (category.id === categoryId.key) {
          category.countProduct = categoryId.doc_count
        }
        return category.id === categoryId.key
      })
    }).filter(cat => cat.id !== 14779 && cat.id !== 2 && cat.id !== 470 && cat.parent_id !== 470)

    if (!isServer) {
      commit(`special-offers/${specialOffersTypes.SPECIAL_OFFERS_SET_CATEGORIES}`, categories, { root: true })
      commit(`special-offers/${specialOffersTypes.SPECIAL_OFFERS_SET_CATEGORIES_IS_LOADING}`, false, { root: true })
    }
  },
  async loadCategoryFilters ({ dispatch }, { category, additionalFilters = [], route }) {
    const shopId = await getCurrentShopId()
    const filterQr = buildFilterProductsQuery({ currentCategory: category, shopId, route })

    if (route.query?.cat) {
      filterQr.applyFilter({ key: 'category_ids', value: { 'in': category.id } })
    }
    if (additionalFilters.length) applyAdditionalFilters({ additionalFilters, query: filterQr })

    const { aggregations, attributeMetadata } = await quickSearchByQuery({
      query: filterQr,
      excludeFields: ['*']
    })

    await dispatch('special-offers/loadAvailableFiltersFrom', {
      aggregations: aggregationRemap(aggregations), attributeMetadata: attributeMetadata, category
    }, { root: true })
  },
  async setProductsLoading ({ commit }, isLoading) {
    commit(types.SET_PRODUCTS_IS_LOADING, isLoading)
  },
  async loadMorePromotions ({ commit }, value) {
    commit(types.SET_IS_ADDITIONAL_PROMOTIONS_LOADING, value);
  },
  async loadMorePromotionProducts ({ commit }, value) {
    commit(types.SET_IS_ADDITIONAL_PROMOTION_PRODUCTS_LOADING, value);
  }
}

export default actions;

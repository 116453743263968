import Vue from 'vue'
import { MutationTree } from 'vuex'
import { PromoOnlyState } from '../types';
import * as types from './mutation-types'

const mutations: MutationTree<PromoOnlyState> = {
  [types.PROMO_ONLY_SET_CATEGORIES] (state, categories) {
    state.categories = categories;
  },
  [types.PROMO_ONLY_SET_PROMO_ONLY_CATEGORY] (state, category) {
    state.promoOnlyCategory = category;
  },
  [types.PROMO_ONLY_ADD_PRODUCTS] (state, products) {
    state.products = products;
  },
  [types.PROMO_ONLY_UPDATE_PRODUCTS] (state, products) {
    state.products = [...state.products, ...products];
  },
  [types.PROMO_ONLY_SET_CATEGORY_FILTERS] (state, { category, filters }) {
    Vue.set(state.filtersMap, category.id, filters)
  },
  [types.PROMO_ONLY_SET_CURRENT_CATEGORY] (state, category) {
    state.currentCategory = category
  },
  [types.PROMO_ONLY_SET_SEARCH_PRODUCTS_STATS] (state, stats = {}) {
    state.searchProductsStats = stats
  },
  [types.PROMO_ONLY_SET_CATEGORIES_IS_LOADING] (state, isLoading) {
    state.categoriesIsLoading = isLoading
  },
  [types.PROMO_ONLY_SET_PRODUCTS_IS_LOADING] (state, isLoading) {
    state.productsIsLoading = isLoading
  },
  [types.SET_IS_ADDITIONAL_LOADING] (state, value) {
    state.isAdditionalLoading = value;
  },
  [types.SET_BANNERS] (state, value) {
    state.banners = value;
  }
};

export default mutations

import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ShippingModuleState, { DeliveryCacheType } from '../types/shipping-module-state'
import { SearchQuery } from 'storefront-query-builder';
import { CheckoutService } from 'theme/store/checkout/data-resolver/CheckoutService';
import * as types from '../types/mutation-types';
import config from 'config'
import { Logger } from '@vue-storefront/core/lib/logger';
import {
  calculateCenter,
  labelWarehouse,
  remapGeocodeByType,
  remapShopsPaymentMethods
} from '$modules/shipping/helpers/shipping-remap';
import { remapCity } from '$modules/shipping/helpers/shipping-city-remap';

import fetch from '@vue-storefront/core/lib/isomorphic-fetch';
import {
  buildShippingInformationPayload,
  buildShippingInformationState,
  findCityByName, groupRemap,
  handleDeliveryLoad, validationShippingNova, validationShippingVarus
} from '$modules/shipping/helpers/shipping-helper';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { groups, sendToLogs } from 'theme/helpers/web-logging';
import { requestDefaultShop } from 'theme/store/checkout/helpers/delivery-defaults-queue';
import * as typesCache from 'theme/store/checkout/types/CheckoutCacheTypes';
import { UserCartService } from 'theme/store/cart/data-resolver/UserCartService';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import modulesConfig from '$modules/config'
import { hasInQueueByParam } from 'theme/store/cart/helpers/syncQueue';
import { ShippingModuleService } from '$modules/shipping/services/shipping-service';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import {
  NewPostCity,
  NewPostLoadCity,
  NewPostLoadSingle,
  NewPostLoadWarehouse,
  NewPostSingle,
  NewPostWarehouse
} from '$modules/shipping/types/shipping-service-types';
import {
  newPostTypes,
  shippingDefaults,
  storageKey,
  storageKeyRegion,
  codes,
  newPostMethods,
  storageKeyModify, pickupMethods, storageKeyPolygon
} from '$modules/shipping/config';
import pointInPolygon from 'point-in-polygon';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { isServer } from '@vue-storefront/core/helpers';
import { shippingNewPostRemap } from '$modules/shipping/utils/shipping-fallback';

const actions: ActionTree<ShippingModuleState, RootState> = {
  async loadCity (ctx, { query, storeCode = null }) {
    if (!query) throw new Error('Load city requires query')

    const defaultCityRequest = await CheckoutService.getCitiesList({
      query,
      storeCode,
      size: 1,
      excludeFields: modulesConfig.cityList.excludeFields
    })

    const remapped = (defaultCityRequest?.items || [])
      .map(remapCity)

    return remapped?.[0] || null
  },
  async initCurrentRegion ({ dispatch, rootGetters, state }, { items = [] }) {
    const array = (rootGetters['url/getCurrentRoute']?.path || '').split('/')
    const firstKey = array.find(i => !!i)

    const currentRegion = items.find(i => i.url_key === firstKey)

    if (state.currentRegion?.id || !currentRegion?.id) return

    dispatch('setCurrentRegion', currentRegion)

    return currentRegion
  },
  async loadRegionList ({ commit, dispatch }) {
    const query = new SearchQuery()

    const response = await quickSearchByQuery({
      query: query,
      entityType: 'tms_region'
    })

    const items = response?.items || []

    commit(types.SM_SET_REGION_LIST, items)

    dispatch('initCurrentRegion', { items })

    return items
  },
  async loadDefaultCity ({ dispatch, commit }, { storeCode = null }) {
    const query = new SearchQuery()
    query.applyFilter({ key: 'is_default', value: { 'eq': true }, scope: 'default' })

    const defaultCity = await dispatch('loadCity', {
      query,
      storeCode
    })

    commit(types.SM_SET_DEFAULT_CITY, defaultCity)

    return defaultCity
  },
  async loadShop (ctx, { query }) {
    const defaultShopRequest = await CheckoutService.getShopsList({
      query,
      size: 1,
      excludeFields: [
        'phone',
        'is_darkhub',
        'fax',
        'emails',
        'work_time',
        'format_id',
        'format_group_id',
        'region',
        'name'
      ]
    }).then(remapShopsPaymentMethods)

    return defaultShopRequest?.items?.[0] || null
  },
  async loadDefaultShop ({ dispatch, commit }, { city }) {
    const query = new SearchQuery()
    query.applyFilter({ key: 'city_id', value: city?.id })
    query.applyFilter({ key: 'is_default', value: { eq: true } })

    const defaultShop = await dispatch('loadShop', { query })

    commit(types.SM_SET_DEFAULT_SHOP, defaultShop)

    return defaultShop
  },
  async requestDefaultCityAndShipping ({ dispatch, state }, { storeCode = null }) {
    const result: { [key: string]: any } = {
      defaultCity: state.defaultCity?.id ? state.defaultCity : null,
      defaultShop: state.defaultShop?.id ? state.defaultShop : null,
      regions: state.regionList.length ? state.regionList : []
    }

    try {
      if (!result.regions.length) {
        await dispatch('loadRegionList')
      }

      if (!result.defaultCity?.id) {
        result.defaultCity = state.defaultCity?.id
          ? state.defaultCity
          : await dispatch('loadDefaultCity', { storeCode })
      }

      if (!result.defaultCity?.id) {
        result.defaultCity = state.defaultCity?.id
          ? state.defaultCity
          : await dispatch('loadDefaultCity', { storeCode })
      }

      if (result.defaultCity?.id && !result.defaultShop?.id) {
        result.defaultShop = state.defaultShop?.id
          ? state.defaultShop
          : await dispatch('loadDefaultShop', { city: result.defaultCity })
      }

      return result
    } catch (e) {
      sendToLogs(
        groups.Shipping,
        'default:cityAndShipping:error',
        { storeCode, error: e?.message }
      )

      return result
    }
  },
  async loadDefaultCityAndShipping ({ dispatch, state, commit }, { storeCode = null }) {
    const result: { [key: string]: any } = {
      defaultCity: state.defaultCity?.id ? state.defaultCity : null,
      defaultShop: state.defaultShop?.id ? state.defaultShop : null,
      regions: []
    }

    try {
      const isEmptyDefaults = !result.defaultCity || !result.defaultShop

      // @ts-ignore
      if (isEmptyDefaults && process?.getDefaultShopCity) {
        // @ts-ignore
        const defaults = await process.getDefaultShopCity()

        result.defaultCity = defaults?.city || null
        result.defaultShop = defaults?.shop || null

        result.defaultCity && commit(types.SM_SET_DEFAULT_CITY, result.defaultCity)
        result.defaultShop && commit(types.SM_SET_DEFAULT_SHOP, result.defaultShop)
        result.regions && commit(types.SM_SET_REGION_LIST, result.regions)
      }

      const defaults = await requestDefaultShop(dispatch, storeCode)

      result.defaultCity = defaults?.defaultCity
      result.defaultShop = defaults?.defaultShop

      return result
    } catch (e) {
      sendToLogs(
        groups.Shipping,
        'default:cityAndShipping:error',
        { storeCode, error: e?.message }
      )

      return result
    }
  },
  async loadCityListPure (ctx, {
    storeCode = null,
    cityId = null,
    includeFields = null,
    excludeFields = modulesConfig.cityList.excludeFields
  }) {
    const query = new SearchQuery()
    query.applyFilter({ key: 'active_shops', value: { 'gt': 0 }, scope: 'default' });

    if (cityId) query.applyFilter({ key: 'id', value: { 'eq': cityId } })

    const { items } = await CheckoutService.getCitiesList({
      query,
      storeCode,
      includeFields: includeFields,
      excludeFields: excludeFields
    })

    return items?.map(remapCity).sort((a, b) => a.id - b.id) || []
  },
  async loadCityList ({ commit, dispatch }, {
    storeCode = null,
    cityId = null,
    includeFields = null,
    excludeFields = modulesConfig.cityList.excludeFields
  }) {
    try {
      const cities = await dispatch('loadCityListPure', {
        storeCode,
        cityId,
        includeFields,
        excludeFields
      })

      if (!storeCode) commit(types.SM_SET_CITY_LIST, cities)

      return cities
    } catch (e) {
      Logger.error('Can not load cities list', 'checkout')()
      return null
    }
  },
  async checkShipping ({
    dispatch,
    getters
  }, items) {
    try {
      const shippingDetails = { ...getters.fallbackShippingDetails }

      if (!shippingDetails.shop) return true

      const currentShippingShopById = items.find(i => +shippingDetails.shop.id === +i.id)
      const currentShippingShop = items.find(i => +shippingDetails.shop.tms_id === +i.tms_id)
      if (!currentShippingShop || !currentShippingShop?.delivery_methods || !currentShippingShopById) {
        throw new Error(`Shop may be disabled for "${shippingDetails.shop.tms_id}"`)
      }

      if (currentShippingShopById.id !== currentShippingShop.id) {
        throw new Error(`Shop may be disabled for "${shippingDetails.shop.tms_id}"`)
      }

      const currentShippingMethods = (currentShippingShop?.delivery_methods || [])
        .sort((a, b) => a.key < b.key ? -1 : 1)

      const shippingMethod = shippingDetails?.shippingMethod;

      const method = currentShippingMethods.find(i => (
        i.key.includes(
          shippingDetails?.deliveryMethod?.key?.toUpperCase()
        )
      ))

      if (!method) {
        throw new Error(`Method disabled "${shippingMethod}" for "${shippingDetails?.shop?.tms_id}"`)
      }

      if (shippingDetails?.deliveryMethod?.last_modified !== method?.last_modified) {
        throw new Error(`Date different for "${shippingDetails?.shop?.tms_id}", before: "${shippingDetails?.deliveryMethod?.last_modified}", after "${method?.last_modified}"`)
      }

      if (shippingDetails?.shippingMethodType?.includes(codes.delivery) && !shippingDetails?.streetAddress?.includes(',')) {
        throw new Error(`Comma missed for address "${shippingDetails?.streetAddress}" on "${shippingDetails?.shippingMethodType}" method.`)
      }

      if (!shippingDetails?.streetAddress.replace(/[\s]/g, '')) {
        throw new Error(`Address don't have house number "${shippingDetails?.streetAddress}" on "${shippingDetails?.shippingMethodType}" method.`)
      }

      return true
    } catch (e) {
      dispatch('clearShipping')

      try {
        // @ts-ignore
        window.errb.push({
          message: e.message + ': ' + JSON.stringify(getters.fallbackShippingDetails),
          level: 'info',
          context: {
            type: 'shipping',
            group: 'shipping/reset'
          }
        })
      } catch (e) {}

      return false
    }
  },
  async loadShopListPure (ctx, {
    cityId = null,
    shopId = null,
    isEnabled = false,
    uploadToWeb = false,
    includeFields = modulesConfig.shopList.includeFields,
    excludeFields = modulesConfig.shopList.excludeFields
  }) {
    const query = new SearchQuery()
    query.applyFilter({ key: 'delivery_methods', value: { in: null } })

    if (cityId) query.applyFilter({ key: 'city_id', value: cityId })
    if (shopId) query.applyFilter({ key: 'id', value: { 'eq': shopId } })
    if (uploadToWeb) query.applyFilter({ key: 'upload_to_web', value: { 'eq': '1' } })
    if (isEnabled) query.applyFilter({ key: 'is_enabled', value: { 'eq': '1' } })

    return CheckoutService.getShopsList({
      query,
      includeFields,
      excludeFields
    }).then(remapShopsPaymentMethods)
  },
  async loadShopList ({ commit, dispatch, state }, {
    cityId = null,
    shopId = null,
    notImportant = false,
    checkShop = false
  }) {
    if (!shopId) throw new Error('shopId field is required')

    if (notImportant && state.loading.shopList) {
      return {
        items: [],
        check: true
      }
    }

    try {
      commit(types.SM_SET_LOADING_SHOP_LIST, true)

      const { items, cache } = await dispatch('loadShopListPure', {
        cityId,
        shopId
      })

      commit(types.SM_SET_SHOP_LIST, items)

      const check = !cache && checkShop ? await dispatch('checkShipping', items) : true

      if (items.length && check) {
        dispatch('updateShipping', { shop: items[0] })
      }

      return {
        check: check,
        items
      }
    } catch (e) {
      Logger.error('Can not load shops list', 'shipping-module')()
    } finally {
      commit(types.SM_SET_LOADING_SHOP_LIST, false)
      commit(types.SM_SET_LOADED_SHOP_LIST, true)
    }
  },
  setDraftCity ({ commit }, { city = null }) {
    commit(types.SM_SET_DRAFT_CITY, city)
  },
  setDraftMethod ({ commit }, { method = null }) {
    if (!method) return

    commit(types.SM_SET_DRAFT_METHOD, method)
    commit(types.SM_SET_DRAFT_ADDRESS, null)
  },
  setDraftShop ({ commit }, { shop = null }) {
    commit(types.SM_SET_DRAFT_SHOP, shop)
  },
  setDraftType ({ commit }, { type = null }) {
    commit(types.SM_SET_DRAFT_TYPE, type)
  },
  setDraftTypeGroup ({ commit }, { group = null }) {
    commit(types.SM_SET_DRAFT_TYPE_GROUP, group)
  },
  setDraftAddress ({ commit }, { address = null }) {
    commit(types.SM_SET_DRAFT_ADDRESS, address)
  },
  clearDraft ({ commit }) {
    commit(types.SM_SET_DRAFT_SHIPPING, shippingDefaults)
  },
  async loadShipping ({
    commit,
    dispatch,
    getters
  }, {
    onlyLocalStorage,
    addressInformation
  }) {
    try {
      commit(types.SM_SET_LOADING_SHIPPING, true)

      const currentString = localStorage.getItem(storageKey)
      const currentStringModify = +localStorage.getItem(storageKeyModify)
      const current = currentString ? JSON.parse(currentString) : null

      if (onlyLocalStorage) {
        dispatch('restoreCurrentRegion')
        dispatch('restoreCurrentPolygon')
      }

      if (!getters.getCurrent?.shop && current) {
        const preparingToSave = {
          city: current.city || null,
          method: current.method || null,
          shop: current.shop || null,
          type: current.type || null,
          address: current.address || null,
          npType: current.npType || 'department',
          npCity: current.npCity || null,
          npShop: current.npShop ? shippingNewPostRemap(current.npShop) : null
        }

        await dispatch('setCurrentShipping', preparingToSave)
      }

      await dispatch('loadDefaultCityAndShipping', {})

      if (onlyLocalStorage || hasInQueueByParam('forceShippingSave')) return

      const currentInStore = getters.getCurrent

      if (!addressInformation) {
        EventBus.$emit('shipping-details-loaded')

        return !currentInStore?.address
      }

      const shippingDetails = await handleDeliveryLoad(addressInformation)

      if (!shippingDetails?.shopId || hasInQueueByParam('forceShippingSave')) return false

      const isNova = (codes.newPost.toUpperCase() === current?.method?.toUpperCase()) ||
        (codes.newPost.toUpperCase() === shippingDetails?.shippingMethodCode?.toUpperCase())

      const isNoChanges = isNova
        ? validationShippingNova(current, shippingDetails)
        : validationShippingVarus(current, shippingDetails)

      if (isNoChanges && currentStringModify === shippingDetails.lastModified) {
        EventBus.$emit('shipping-details-loaded')

        return true
      }

      if (currentStringModify > shippingDetails.lastModified) return

      const newPost = {
        npCity: null,
        npShop: null,
        npType: null
      }

      const toPromise = [
        dispatch('loadCityList', {
          cityId: shippingDetails.cityId
        }),
        dispatch('loadShopList', {
          shopId: shippingDetails.shopId,
          checkShop: false
        })
      ]

      if (isNova && shippingDetails?.newPost?.city_ref && shippingDetails?.newPost?.ref) {
        toPromise.push(
          dispatch('singleNewPostCity', {
            ref: shippingDetails?.newPost?.city_ref
          }),
          dispatch('singleNewPostShop', {
            ref: shippingDetails?.newPost?.ref
          })
        )
      }

      const [
        ,,
        city,
        shop
      ] = await Promise.all(toPromise)

      newPost.npCity = city || null
      newPost.npShop = shop || null
      newPost.npType = (shop?.postbox ? 'poshtomat' : 'department') || null

      if (hasInQueueByParam('forceShippingSave')) return

      const shippingDetailsState = buildShippingInformationState(
        shippingDetails,
        getters.getCityList,
        getters.getShopList,
        newPost
      )

      const emitDetailsChanges = !(shippingDetailsState?.shop?.id === current?.shop?.id)

      await dispatch('setCurrentShipping', shippingDetailsState)

      localStorage.setItem(storageKey, JSON.stringify(shippingDetailsState))
      localStorage.setItem(storageKeyModify, shippingDetails.lastModified)

      if (emitDetailsChanges) {
        EventBus.$emit('shipping-details-changed')
      }

      dispatch('checkout/clearCheckoutSession', true, { root: true })

      EventBus.$emit('shipping-details-loaded')

      return true
    } catch (e) {
      localStorage.removeItem(storageKey)
      localStorage.removeItem(storageKeyModify)
      localStorage.removeItem(storageKeyPolygon)

      sendToLogs(
        groups.Shipping,
        'shipping:load:catch:error',
        { message: e?.message }
      )

      return false
    } finally {
      commit(types.SM_SET_LOADING_SHIPPING, false)
      commit(types.SM_SET_LOADED_SHIPPING, true)
      EventBus.$emit('shipping-details-finished')

      dispatch('checkRegion')
    }
  },
  checkRegion ({ state, dispatch }) {
    try {
      const ids = state.currentRegion?.city_ids || []
      const currentCityId = state.current?.city?.id

      if (ids.includes(currentCityId)) return

      const region = (state?.regionList || []).find(i => {
        const regionIds = i?.city_ids || []

        return regionIds.includes(currentCityId)
      })

      if (!region) return
      dispatch('setCurrentRegion', { ...region })
    } catch (e) {}
  },
  async updateShipping ({ getters, dispatch }, { shop }) {
    if (
      !getters.getCurrent?.shop ||
        !shop ||
        getters.getCurrent?.shop?.id !== shop?.id
    ) return

    const preparingToSave = {
      ...getters.getCurrent,
      shop
    }

    await dispatch('setCurrentShipping', preparingToSave)

    localStorage.setItem(storageKey, JSON.stringify(preparingToSave))
  },
  async saveShipping ({ state, dispatch }, payload) {
    const draft = { ...state.draft }

    if (!newPostMethods.includes(draft.method)) {
      draft.npShop = null
      draft.npCity = null
      draft.npType = 'department'
    } else {
      dispatch('setCurrentRegion', null)
    }

    localStorage.setItem(storageKey, JSON.stringify(draft))

    await dispatch('setCurrentShipping', draft)

    const reg = state.regionList?.find(i => i.shop_ids.includes(draft?.shop?.id))

    dispatch('setCurrentRegion', reg || null)

    await dispatch('clearDraft')
    await dispatch('checkout/clearCheckoutSession', true, { root: true })

    await dispatch(
      'cart/sync',
      {
        forceServerSync: true,
        forceShippingSave: true,
        skipPull: true
      },
      { root: true }
    )

    if (!payload?.productToAdd) return

    const adultConfirmation = await dispatch('ui/adultConfirmation', { product: payload.productToAdd }, { root: true })

    if (adultConfirmation) return

    dispatch('cart/addProductToCart', payload.productToAdd, { root: true })
  },
  initDraft ({ commit }, payload) {
    if (!payload?.city || !payload?.shop) return

    commit(types.SM_SET_DRAFT_SHIPPING, { ...payload })
  },
  async loadCityPolygons ({ commit, state }, { tms_id }) {
    const query = new SearchQuery()

    if (!tms_id) return []

    query.applyFilter({ key: 'tms_id', value: { 'eq': tms_id } })

    try {
      if (+tms_id === +state.deliveryPolygon.tms_id && !!state.deliveryPolygon.list.length) { return [] }

      const cities = await CheckoutService.getCitiesList({
        query,
        includeFields: ['tms_id', 'district_polygon']
      })

      const list = (cities.items?.[0]?.district_polygon?.[0] || []).map(i => [i.lng, i.lat])
      const tmsId = cities.items?.[0]?.tms_id || []

      commit(types.SM_SET_DELIVERY_POLYGON, { list, tms_id: tmsId })

      return list
    } catch (e) {
      commit(types.SM_SET_DELIVERY_POLYGON, { list: [], tms_id })

      Logger.error('Can not load cities list', 'checkout')()
      return []
    }
  },
  async loadDeliveryTypes ({ commit }, {
    lat = 0,
    lng = 0,
    cityId = null
  }) {
    if ((!lat || !lng)) return null

    const res = await CheckoutService.deliveryTypesByLocation(+lat, +lng, cityId || null)

    const methods = Object.keys(res.result) || []

    if (res.code !== 200 || !methods.length) return null

    const deliveryCache: DeliveryCacheType = {
      coordinates: {
        lat: +lat,
        lng: +lng
      },
      ttl: Date.now() + (60 * 5 * 1000),
      shopDeliveryPolygon: res?.result?.delivery?.id || false
    }

    localStorage.setItem(storageKeyPolygon, JSON.stringify(deliveryCache))

    commit(types.SM_SET_DELIVERY_CACHE, {
      ...deliveryCache,
      loaded: true
    })

    return res.result
  },
  async loadGeoLocateShop ({
    dispatch
  }, {
    lat = 0,
    lng = 0,
    cityId = null,
    typeDelivery = null
  }) {
    const types = await dispatch('loadDeliveryTypes', {
      lat,
      lng,
      cityId
    })

    if (typeDelivery === codes.newPost.toLowerCase()) {
      const delivery = types?.delivery_nova

      return delivery ? {
        key: delivery?.default_delivery_type,
        group: groupRemap(delivery?.available_delivery_types, delivery?.delivery_methods || []),
        shop: delivery
      } : null
    }

    const delivery = types?.delivery

    return {
      key: delivery?.default_delivery_type,
      group: groupRemap(delivery?.available_delivery_types, delivery?.delivery_methods || []),
      shop: delivery
    }
  },
  async loadGeocodeByCoordinate (ctx, {
    lat = 0,
    lng = 0
  }) {
    if (!lat || !lng) throw new Error('Coordinates not set')

    const endpoint = 'https://api.visicom.ua/data-api/5.0/uk/geocode.json'
    const queries = []

    queries.push(`near=${lng},${lat}`)
    queries.push('c=ua')
    queries.push('ci=adr_address,adr_street')
    queries.push('order=distance')
    queries.push(`key=${config.visicom.apiKey}`)
    queries.push('l=1')
    queries.push('r=150')

    const url = `${endpoint}?${queries.join('&')}`

    return fetch(url)
      .then(d => d.json())
      .then(d => remapGeocodeByType(d, true))
  },
  async loadGeocodeByText (ctx, { lat, lng, text }) {
    const endpoint = 'https://api.visicom.ua/data-api/5.0/uk/geocode.json'
    const queries = []

    const search = (text || '').trim()

    if (!search.length) return []

    queries.push(`t=${encodeURIComponent(search)}`)
    queries.push(`n=${lng},${lat}`)
    queries.push('c=ua')
    queries.push('ci=adr_address,adr_street')
    queries.push('order=distance')
    queries.push(`key=${config.visicom.apiKey}`)
    queries.push('l=100')

    const url = `${endpoint}?${queries.join('&')}`

    return fetch(url)
      .then(d => d.json())
      .then(remapGeocodeByType)
  },
  async loadLocalizedCities ({ commit, getters, dispatch }) {
    let cities = getters.getCityListForGeocode || []

    if (!cities.length) {
      cities = await dispatch('loadCityList', {
        storeCode: 'ua',
        includeFields: [
          'id',
          'tms_id',
          'name'
        ],
        excludeFields: null
      })

      commit(types.SM_SET_CITY_LIST_FOR_GEOCODE, cities)
    }

    return cities
  },
  async findCityByName ({ dispatch }, { name }) {
    const cities = await dispatch('loadLocalizedCities')
    return findCityByName(cities, name)
  },
  async setDeliveryByLocation ({ dispatch, commit, getters }, { lat, lng }) {
    try {
      const currentString = localStorage.getItem(storageKey)

      if (currentString || getters.getGeocodeLoading) return

      commit(types.SM_SET_LOADING_GEOCODE, true)

      const geocode = await dispatch('loadGeocodeByCoordinate', {
        lat,
        lng
      })

      const cityByName = await dispatch('findCityByName', { name: geocode?.settlement })

      if (!cityByName) return

      const query = new SearchQuery()
      query.applyFilter({ key: 'id', value: { 'eq': cityByName.id }, scope: 'default' })

      const [
        shop,
        city
      ] = await Promise.all([
        dispatch('loadGeoLocateShop', { lat, lng, cityId: cityByName.id }),
        dispatch('loadCity', { query })
      ])

      if (!shop || !city || geocode?.category !== 'adr_address') return

      const draft = {
        ...shippingDefaults,
        city: city,
        method: codes.delivery,
        shop: shop.shop,
        type: shop.type,
        address: geocode
      }

      localStorage.setItem(storageKey, JSON.stringify(draft))

      await dispatch('setCurrentShipping', draft)
    } catch (e) {} finally {
      commit(types.SM_SET_LOADING_GEOCODE, false)
      commit(types.SM_SET_LOADED_GEOCODE, true)
    }
  },
  async syncShipping ({
    getters,
    dispatch
  }, {
    addressInformation,
    clientStateSave = false
  }) {
    if (hasInQueueByParam('forceShippingSave')) return null

    if (!clientStateSave || (clientStateSave && !getters.getCurrent?.shop)) {
      const loadShipping = await dispatch('loadShipping', { addressInformation })

      if (loadShipping) return null
    }

    const saveShipping = await dispatch('saveShippingSync')

    return saveShipping || null
  },
  async saveShippingSync ({
    getters,
    rootGetters
  }) {
    try {
      const current = getters.getCurrent

      localStorage.setItem(storageKey, JSON.stringify(current))

      EventBus.$emit('shipping-details-changed')

      const currentTimeslot = rootGetters['cart/getCartToken'] && rootGetters['checkout/getCurrentTimeSlot']

      return buildShippingInformationPayload(current, currentTimeslot) || null
    } catch (e) {
      sendToLogs(
        groups.Shipping,
        'shipping:saveShippingDetails:catch:error',
        { message: e?.message }
      )

      Logger.error(`Error on save delivery: ${e}`)()
    }
  },
  async clearShipping ({
    rootGetters,
    dispatch
  }) {
    try {
      const shippingStorage = StorageManager.get(typesCache.CHECKOUT_CACHE)

      await shippingStorage.removeItem(
        typesCache.SHIPPING_CACHE_DELIVERY_COORDINATES
      ).catch((reason) => {
        Logger.error(reason)
      })

      await dispatch('setCurrentShipping', shippingDefaults)

      localStorage.removeItem(storageKey)
      localStorage.removeItem(storageKeyModify)
      localStorage.removeItem(storageKeyPolygon)

      dispatch('ui/setUserLocate', true, { root: true })
      dispatch('checkout/clearCheckoutSession', true, { root: true })

      const cartToken = rootGetters['cart/getCartToken']

      if (cartToken) {
        await UserCartService.cartExt(
          cartToken,
          {
            remove_address_information: true
          }
        )
      }
    } catch (e) {

    } finally {
      sendToLogs(
        groups.Shipping,
        'shipping:clear',
        'done'
      )
    }
  },
  async singleNewPostCity ({ commit }, payload: NewPostLoadSingle) {
    const body: NewPostSingle = {
      storeCode: currentStoreView().storeCode,
      ref: payload.ref,
      type: 'city'
    }

    const { result } = await ShippingModuleService.getNewPostSingle(body)

    if (!result) return

    commit(types.SM_SET_NP_SHOP_LIST, result || [])

    return result?.[0]
  },
  async loadDeliveryType ({ dispatch, state }, {
    lat = 0,
    lng = 0
  }) {
    if (
      (
        state.deliveryCache?.coordinates?.lat === +lat &&
        state.deliveryCache?.coordinates?.lng === +lng &&
        state.deliveryCache?.ttl > Date.now()
      ) && state.deliveryCache?.loaded
    ) {
      return state.deliveryCache?.shopDeliveryPolygon
    }

    const types = await dispatch('loadDeliveryTypes', {
      lat,
      lng,
      cityId: null
    })

    return types?.delivery?.id || false
  },
  async checkDeliveryByCoordinates ({ dispatch }, shipping) {
    if (shipping?.npShop?.lat && shipping?.npShop?.long) {
      return dispatch('loadDeliveryType', {
        lat: shipping?.npShop?.lat,
        lng: shipping?.npShop?.long
      })
    }

    const [lat, lng] = shipping?.address?.coordinates || []

    if (lat && lng) {
      return dispatch('loadDeliveryType', {
        lat: lat,
        lng: lng
      })
    }

    if (shipping?.shop?.lat && shipping?.shop?.long) {
      return dispatch('loadDeliveryType', {
        lat: shipping?.shop?.lat,
        lng: shipping?.shop?.long
      })
    }

    return false
  },
  async setCurrentShipping ({ commit, dispatch }, shipping) {
    if (pickupMethods.includes(shipping?.type)) {
      shipping.address = null
    }

    const shopDelivery = await dispatch('checkDeliveryByCoordinates', shipping)

    commit(types.SM_SET_CURRENT_SHIPPING, shipping)
    commit(types.SM_SET_SHOP_DELIVERY_POLYGON, shopDelivery || false)
  },
  async singleNewPostShop ({ commit }, payload: NewPostLoadSingle) {
    const body: NewPostSingle = {
      storeCode: currentStoreView().storeCode,
      ref: payload.ref,
      type: 'warehouse'
    }

    const { result } = await ShippingModuleService.getNewPostSingle(body)

    if (!result || !Array.isArray(result)) return

    const list = (result || []).map((i) => {
      const r = shippingNewPostRemap(i)

      return labelWarehouse(r)
    })

    commit(types.SM_SET_NP_SHOP_LIST, list || [])

    return list?.[0]
  },
  async loadNewPostCity ({ commit }, payload: NewPostLoadCity) {
    const body: NewPostCity = {
      storeCode: currentStoreView().storeCode,
      q: payload.q
    }

    if (payload.q) body.q = payload.q

    const { result } = await ShippingModuleService.getNewPostCity(body)

    const list = (result || []).map(i => {
      const settlement = [
        i.settlement_region,
        i.settlement_area
      ].filter(i => !!i)

      const additional = settlement.length ? ` (${settlement.join(', ')})` : ''

      return {
        ...i,
        description: i.settlement_description + additional
      }
    })

    commit(types.SM_SET_NP_CITY_LIST, list)

    return list
  },
  async loadNewPostWarehouseRequest ({ commit }, payload: NewPostLoadWarehouse) {
    const body: NewPostWarehouse = {
      storeCode: currentStoreView().storeCode,
      isPostMachine: payload.isPostMachine,
      cityRef: payload.cityRef
    }

    if (payload.q) body.q = payload.q

    const res = await ShippingModuleService.getNewPostWarehouse(body)

    const { result: warehouse } = res

    const square = { north: 44, south: 52.5, east: 40.5, west: 22 }

    const polygon = [
      [square.east, square.south],
      [square.west, square.south],
      [square.west, square.north],
      [square.east, square.north]
    ]

    const result = warehouse.filter(i => (
      (+i.long) &&
      (+i.lat) &&
      pointInPolygon([+i.long, +i.lat], polygon)
    ))

    const list = result.map((i) => {
      const r = shippingNewPostRemap(i)

      return labelWarehouse(r)
    })

    const coords = result.map(i => [+i.long, +i.lat])
    const center = calculateCenter(coords)

    commit(types.SM_SET_NP_CENTER, center)
    commit(types.SM_SET_NP_SHOP_LIST, list || [])
  },
  async setNewPostCity ({ commit, dispatch }, payload) {
    if (!payload) {
      commit(types.SM_SET_NP_CITY_CURRENT, null)
      commit(types.SM_SET_NP_SHOP_LIST, [])

      return
    }

    commit(types.SM_SET_NP_SHOP_CURRENT, null)

    await dispatch('loadNewPostWarehouse', {
      ref: payload?.ref
    })

    commit(types.SM_SET_NP_CITY_CURRENT, payload)
  },
  async loadNewPostWarehouse ({ commit, dispatch, state }, payload) {
    commit(types.SM_SET_NP_SHOP_LIST, [])

    await dispatch('loadNewPostWarehouseRequest', {
      cityRef: payload?.ref,
      isPostMachine: payload.npType === newPostTypes.poshtomat || state.draft?.npType === newPostTypes.poshtomat
    })
  },
  setNewPostShop ({ commit }, payload) {
    commit(types.SM_SET_NP_SHOP_CURRENT, payload)
  },
  async setNewPostType ({ commit, dispatch, state }, payload) {
    commit(types.SM_SET_NP_TYPE, payload || newPostTypes.department)

    if (!state.draft?.npCity?.ref) return

    await dispatch('loadNewPostWarehouseRequest', {
      cityRef: state.draft?.npCity?.ref,
      isPostMachine: state.draft?.npType === newPostTypes.poshtomat
    })
  },
  setCurrentRegion ({ commit, state, getters }, regionToSave) {
    const oldRegion = state?.currentRegion ? { ...state?.currentRegion } : {}
    const region = getters.isCurrentNewPost ? null : { ...regionToSave }

    if (!oldRegion?.id && !region?.id) return

    commit(types.SM_SET_CURRENT_REGION, region || {})

    if (isServer) return

    localStorage.setItem(storageKeyRegion, JSON.stringify(region))

    EventBus.$emit('region-changed', {
      region: region,
      oldRegion: oldRegion
    })
  },
  restoreCurrentPolygon ({ commit, state }) {
    if (state?.deliveryCache?.loaded) return

    const currentString = localStorage.getItem(storageKeyPolygon)

    if (!currentString) return;

    const deliveryCache = JSON.parse(currentString)

    if (!deliveryCache) return;

    commit(types.SM_SET_DELIVERY_CACHE, deliveryCache)
    commit(types.SM_SET_SHOP_DELIVERY_POLYGON, deliveryCache?.shopDeliveryPolygon)
  },
  restoreCurrentRegion ({ commit, state }) {
    const currentString = localStorage.getItem(storageKeyRegion)
    const currentRegion = state?.currentRegion ? { ...state?.currentRegion } : {}

    if (currentRegion?.id) {
      localStorage.setItem(storageKeyRegion, JSON.stringify(currentRegion))
      return
    }

    if (!currentString) return

    const region = JSON.parse(currentString)

    if (!region || region?.id === currentRegion.id) return

    commit(types.SM_SET_CURRENT_REGION, region)
    EventBus.$emit('region-changed', region)
  },
  setRegionList ({ commit }, payload) {
    commit(types.SM_SET_REGION_LIST, payload || [])
  }
}

export default actions

import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import { PromoOnlyState } from '../types';
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export const PromoOnlyStore: Module<PromoOnlyState, RootState> = {
  namespaced: true,
  state: {
    categories: [],
    promoOnlyCategory: null,
    products: [],
    filtersMap: {},
    currentCategory: null,
    searchProductsStats: {},
    categoriesIsLoading: true,
    productsIsLoading: true,
    isAdditionalLoading: false,
    banners: []
  },
  actions,
  mutations,
  getters
}

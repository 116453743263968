import { PromotionStore } from './store'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { getSearchAdapter } from '@vue-storefront/core/lib/search/adapter/searchAdapterFactory';

export const PromotionModule: StorefrontModule = async function ({ store }, fromCache) {
  if (fromCache?.promotion) {
    PromotionStore.state = fromCache.promotion
  }

  store.registerModule('promotion', PromotionStore)

  getSearchAdapter().then((searchAdapter) => {
    searchAdapter.registerEntityType('promotion_category', {
      queryProcessor: (query) => {
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'promotion_category', start, size)
      }
    });
    searchAdapter.registerEntityType('promotion_page', {
      queryProcessor: (query) => {
        return query
      },
      resultProcessor: (resp, start, size) => {
        return searchAdapter.handleResult(resp, 'promotion_page', start, size)
      }
    });
  });
}

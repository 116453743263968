import { getCurrentRegion, getRegionList } from 'theme/store/checkout/helpers';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

const toRedirect = [
  'CategoryPage',
  'Home',
  'SpecialOffers',
  'Promotion',
  'PromotionPage',
  'Brand',
  'Brands'
]

export const regionUrl = (route: any, currentRegion: any, nextRegion: any) => {
  const storeCode = currentStoreView().storeCode === 'ua' ? '' : 'ru'
  const storeCodePrefix = storeCode ? `/${storeCode}` : ''

  const currentComponent = (
    route.matched?.[0]?.components?.default ||
    route.matched?.[0]?.instances?.default?.$options
  )?.name;

  if (!toRedirect.includes(currentComponent)) return null

  if (currentRegion?.url_key === nextRegion?.url_key) return

  if (currentRegion?.url_key && !nextRegion?.url_key) {
    const from = new RegExp(`^(${storeCodePrefix}/${currentRegion.url_key})`, 'g')

    return route.fullPath.replace(from, storeCodePrefix) || '/'
  }

  if (currentRegion?.url_key && route.fullPath.startsWith(`${storeCodePrefix}/${currentRegion.url_key}`)) {
    const from = new RegExp(`^(${storeCodePrefix}/${currentRegion.url_key})`, 'g')

    return route.fullPath.replace(from, `${storeCodePrefix}/${nextRegion.url_key}`)
  }

  if (storeCodePrefix) {
    const from = new RegExp(`^(${storeCodePrefix})`, 'g')

    return `${storeCodePrefix}/${nextRegion.url_key}${route.fullPath.replace(from, '')}`
  }

  return `/${nextRegion.url_key}${route.fullPath}`
}

export const isRegionalPage = (dataUrl: string, override: any = null) => {
  const region = override || getCurrentRegion()

  if (!region?.url_key) return false

  const storeCode = currentStoreView().storeCode === 'ua' ? '' : '/ru'

  const from = new RegExp(`^(${storeCode}/${region.url_key})`, 'g')

  return from.test(dataUrl)
}

const regexFirstGroup = /^(?!\/ru$)\/(?:ru\/)?([^/]+)/

export const compareRegions = (dataFrom: string, dataTo: any = null) => {
  const region = getCurrentRegion()

  const regionToKey = (dataTo || '').match(regexFirstGroup)?.[1]
  const regionFromKey = (dataFrom || '').match(regexFirstGroup)?.[1]

  if (regionToKey === regionFromKey || region.slug === regionToKey) return false

  const list = getRegionList()

  return (list || []).find(i => i.slug === regionToKey)
}

export const clearRegionUrl = (url: string, override: any = null) => {
  const region = override || getCurrentRegion()

  if (!region?.url_key) return url

  const storeCode = currentStoreView().storeCode === 'ua' ? '' : '/ru'

  const from = new RegExp(`^(${storeCode}/${region.url_key})`, 'g')

  return url?.replace(from, '')
}

export const formatRegionUrl = (dataUrl: string): string => {
  try {
    const url = dataUrl || ''
    const region = getCurrentRegion()

    const storeCode = currentStoreView().storeCode === 'ua' ? '' : 'ru'

    if (!region?.url_key) return url

    const from = new RegExp(`^(/${region.url_key})`, 'g')
    const data = url?.replace(from, '')?.split('/') || []
    const slug = data?.filter(i => !!i) || []

    const prepare = []

    if (
      (storeCode && slug?.[1] === region?.url_key) ||
      (!storeCode && slug?.[0] === region?.url_key)
    ) {
      return url
    }

    if (storeCode && slug?.[0] === storeCode) {
      prepare.push(slug.shift())
    }

    prepare.push(
      region?.url_key,
      ...slug
    )

    return `/${prepare.join('/')}`
  } catch (e) {
    return dataUrl
  }
}

<template>
  <div class="swl">
    <div
      class="swl__wrap"
      @click="openShippingModal"
    >
      <ShippingWidgetPlaceholder
        v-if="isLoading || !isMounted"
      />
      <p v-else class="swl__address">
        <span class="swl__delivery-method">
          {{ deliveryMethod ? $t(deliveryMethod) : $t('Address') }}
        </span>
        <input
          v-if="currentAddress"
          ref="currentAddress"
          class="swl__delivery-address"
          :class="{'swl__delivery-address--rtl': this.isRTL}"
          type="text"
          :value="currentAddress"
          readonly
        >
        <SfButton
          v-else
          class="swl__link sf-button--pure sf-link--primary"
          @click.native="openShippingModal"
        >
          {{ placeholder }}
        </SfButton>
      </p>
      <div class="swl__dropdown">
        <SfChevron />
      </div>
    </div>
  </div>
</template>

<script>
import { SfButton, SfChevron } from '@storefront-ui/vue';
import { mapState } from 'vuex';
import DeviceType from 'theme/mixins/DeviceType';
import { isServer } from '@vue-storefront/core/helpers';
import ShippingControlsMixin from '$modules/shipping/mixins/shipping-controls-mixin';
import ShippingWidgetPlaceholder from '$modules/shipping/components/shipping-widget-placeholder.vue';
import { codes, deliveryMethods } from '$modules/shipping/config';

export default {
  name: 'ShippingWidgetLite',
  components: {
    ShippingWidgetPlaceholder,
    SfButton,
    SfChevron
  },
  mixins: [DeviceType, ShippingControlsMixin],
  watch: {
    triggerResize: {
      handler: function () {
        this.$nextTick(() => this.setRtl())
      }
    }
  },
  data () {
    return {
      isRTL: false,
      isMounted: false
    }
  },
  computed: {
    ...mapState({
      getDefaultCity: state => state['shipping-module'].defaultCity,
      getCurrentRegion: state => state['shipping-module'].currentRegion,
      deliveryLoader: state => state.ui.deliveryLoader,
      currentShipping: state => state['shipping-module'].current,
      getShippingLoading: state => state['shipping-module'].loading.shipping
    }),
    triggerResize () {
      return [this.isLoading, this.$mq, this.currentAddress, this.isMounted]
    },
    isLoading () {
      return this.deliveryLoader || this.getShippingLoading
    },
    isUserHasAddress () {
      return Boolean(this.currentShipping?.shop?.id)
    },
    placeholder () {
      return this.getCurrentRegion?.name || this.$t('Enter the address')
    },
    currentShippingMethod () {
      return this.currentShipping?.type?.toLowerCase()
    },
    currentAddress () {
      if (this.currentShipping?.method === codes.newPost) {
        return [
          this.currentShipping?.npCity?.settlement_description
            ? `${this.$t('c.')} ${this.currentShipping?.npCity?.settlement_description}` : '',
          this.currentShipping?.npShop?.label || ''
        ]
          .filter(i => !!i)
          .join(', ')
      }

      return this.prepareCurrentAddress
    },
    isDeliveryShippingMethod () {
      return deliveryMethods.includes(this.currentShipping?.method)
    },
    prepareCurrentAddress () {
      const address = this.currentShipping.address?.label || this.currentShipping?.shop?.address

      return this.isDeliveryShippingMethod
        ? (address || '')
        : [
          this.currentShipping?.city?.name || '',
          address || ''
        ]
          .filter(i => !!i)
          .join(', ')
    },
    deliveryMethod () {
      return this.isUserHasAddress ? this.currentShippingMethod : null
    }
  },
  methods: {
    setRtl () {
      if (isServer || !this.$refs.currentAddress) return

      this.isRTL = this.$refs.currentAddress?.scrollWidth > (this.$refs.currentAddress?.clientWidth + 1)
    }
  },
  mounted () {
    this.isMounted = true
    this.$nextTick(() => this.setRtl())
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import '~theme/css/fonts';

.swl {
  line-height: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  z-index: 3;
  position: relative;

  @include for-mobile {
    z-index: 2;
  }

  &__address {
    margin: 0;
    font-size: var(--font-sm);
    flex: 1;
    width: 100%;

    @media (min-width: $desktop-min) {
      width: 242px;
    }
  }

  &__delivery-method {
    font-size: var(--font-size-12);
    color: var(--orange);
    font-weight: bold;
    display: block;
    margin-bottom: var(--spacer-4);

    @media (min-width: $desktop-min) {
      color: var(--dark-gray);
      font-weight: normal;
    }
  }

  &__delivery-address {
    font-size: var(--font-sm);
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    user-select: none;
    cursor: pointer;
    pointer-events: none;

    &--rtl {
      direction: rtl;
    }
  }

  &__link {
    font-size: var(--font-sm);
    line-height: var(--font-base);
    color: var(--black);
    white-space: nowrap;

    @include for-desktop {
      margin-left: 0;
    }

    &.sf-button {
      font-weight: var(--font-normal);
    }
  }

  &__wrap {
    font-weight: var(--font-normal);
    display: flex;
    align-items: center;
    line-height: 1;
    padding: 0;
    text-align: left;
    max-width: 100%;
    width: 100%;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    gap: 10px;

    @include font-icon(var(--icon-location));

    &:before {
      font-size: var(--font-size-13);
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 13px;
      height: 13px;
      color: var(--orange);
    }

    &:active, &:focus {
      outline: none;
    }
  }
}

.swl__dropdown {
  --chevron-size: 9px;
}

::v-deep {
  .sf-chevron__bar:after {
    height: 2px;
  }
}
</style>

import i18n from '@vue-storefront/i18n';

const remapStreet = (properties) => {
  const street = []

  if (properties.settlement_type && properties.settlement) {
    street.push(`${properties.settlement_type} ${properties.settlement}`)
  }

  if (properties.street_type && properties.street) {
    street.push(`${properties.street_type} ${properties.street}`)
  } else if (properties.type && properties.name) {
    street.push(`${properties.type} ${properties.name}`)
  }

  return {
    label: street.join(', '),
    address: {
      street: street.join(', '),
      house: null
    }
  }
}

const remapAddress = (properties) => {
  const { label, address } = remapStreet(properties)

  return {
    label: [label, properties.name].filter(i => !!i).join(', '),
    address: {
      ...address,
      house: properties.name || null
    }
  }
}

const remapGeocoders = {
  adr_street: remapStreet,
  adr_address: remapAddress
}

export const remapGeocode = (item) => {
  const remapped = remapGeocoders[item?.properties?.categories]

  const center = item?.geo_centroid?.coordinates

  const data = remapped ? remapped(item?.properties) : null

  const result: any = {
    label: data ? data.label : null,
    address: data ? data.address : null,
    coordinates: center,
    category: item?.properties?.categories,
    settlement: item?.properties?.settlement
  }

  return result
}

export const remapGeocodeByType = (data, single = false) => {
  if (!['Feature', 'FeatureCollection'].includes(data?.type)) return single ? null : []

  const items = data.type === 'Feature' ? [data] : (data?.features || [])

  if (single) return items?.[0] ? remapGeocode(items[0]) : null

  return items.map(remapGeocode)
}

export const calculateSquare = (list) => (
  list.reduce((a, b) => {
    const [lng, lat] = b

    a.north = a.north ? (lat > a.north ? lat : a.north) : lat
    a.south = a.south ? (lat < a.south ? lat : a.south) : lat
    a.east = a.east ? (lng > a.east ? lng : a.east) : lng
    a.west = a.west ? (lng < a.west ? lng : a.west) : lng

    return a
  }, {
    north: false,
    south: false,
    east: false,
    west: false
  })
)

export const calculateCenter = (list) => {
  const square = calculateSquare(list)

  const lat = square.south + ((square.north - square.south) / 2)
  const lng = square.west + ((square.east - square.west) / 2)

  return [lng, lat]
}

export const remapBounds = (square) => (
  [
    [square.east, square.south],
    [square.west, square.south],
    [square.west, square.north],
    [square.east, square.north]
  ]
)

export const fallbackDefaults = (current, loaded, getShippingMethods) => {
  const methods = (current?.shop?.delivery_methods || [])
    .map(i => typeof i === 'string' ? JSON.parse(i) : i)
    .sort((a, b) => a.key < b.key ? -1 : 1)

  const method = methods.find(i => i.key.includes(current?.method?.toUpperCase()))

  const currentShippingMethod = (getShippingMethods || []).find(i => i.key === current?.method)
  const [lon, lat] = current?.address?.coordinates || []

  const maxWeightNp = current?.npShop?.wgh ? current.npShop.wgh * 1000 : 0

  const city = current?.npCity?.settlement_description ? `${i18n.t('c.')} ${current?.npCity?.settlement_description}` : ''

  return {
    cityDetails: current?.city,
    cityLocation: {
      latLng: current?.city?.coordinates,
      region: current?.city?.name
    },
    city: city || current?.city?.name,
    shippingMethod: current?.method,
    shippingCarrier: current?.method,
    shippingMethodType: current?.type,
    shippingMethodTypes: [],
    streetAddress: current?.npShop?.label || current.address?.label || current?.shop?.address,
    deliveryAddressCoords: {
      lat: lat,
      lng: lon
    },
    shopId: current?.shop?.id,
    shop: current?.shop ? {
      ...current.shop,
      delivery_methods: methods
    } : null,
    deliveryMethod: {
      ...(method || {}),
      max_weight: maxWeightNp || method?.max_weight
    },
    availablePaymentMethods: method?.payment_methods,
    userFilledShippingInfo: !!current?.shop,
    userLoadedShippingInfo: loaded,
    currentShippingMethod,
    newPostShop: current?.npShop,
    country: 'UA'
  }
}

const remapPaymentMethod = (shop) => {
  try {
    if (!shop?.delivery_methods) return shop

    const remappedShop = { ...shop }

    remappedShop.delivery_methods = shop.delivery_methods.map(i => typeof i === 'string' ? JSON.parse(i) : i)

    return remappedShop
  } catch (e) {
    return shop
  }
}

export const remapShopsPaymentMethods = (result) => {
  result.items = result.items.map(remapPaymentMethod).sort((a, b) => a.id - b.id)

  return result
}

export const labelWarehouse = (i) => {
  const label = i18n.t(i.postbox ? 'Poshtomat' : 'Department')

  return {
    ...i,
    label: `${label} №${i.number} (${i18n.t('under weight on place', { weight: i.wgh })}): ${i.address}`
  }
}
